<template>
    <v-navigation-drawer
        v-model="show"
        temporary
        right
        app
        width="650"
        color="grey lighten-4"
        class="pa-4"
    >
        <template v-if="loading">
            <div class="d-flex align-center justify-space-between">
                <v-skeleton-loader
                  type="text"
                  width="200"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="text"
                  width="100"
                ></v-skeleton-loader>
            </div>
            <div class="tw-grid tw-gap-4 md:tw-grid-cols-3 mt-4">
                <v-skeleton-loader
                  type="image"
                  height="70"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="image"
                  height="70"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  type="image"
                  height="70"
                ></v-skeleton-loader>
            </div>
            <v-skeleton-loader
              type="image"
              height="40"
              class="mt-4"
            ></v-skeleton-loader>
            <v-skeleton-loader
              type="table-tbody"
              class="mt-4"
            ></v-skeleton-loader>
        </template>
        <template v-else>
            <h1 class="text-h6 d-flex justify-space-between font-weight-light">
                <div>Payment #{{ info.meta.id }}</div>
                <div>{{ info.date_for_human }}</div>
            </h1>
            <div class="tw-grid tw-gap-4 md:tw-grid-cols-3 mt-4">
                <v-card color="orange lighten-4" flat>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="headline">{{ info.amount_due_for_human }}</v-list-item-title>
                            <v-list-item-subtitle>Due</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
                <v-card color="green lighten-4" flat>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="headline">{{ info.amount_paid_for_human }}</v-list-item-title>
                            <v-list-item-subtitle>Paid</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
                <v-card color="red lighten-4" flat>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="headline">{{ info.balance_for_human }}</v-list-item-title>
                            <v-list-item-subtitle>Balance</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </div>
            <div v-for="item in info.items" :key="item.id" class="mt-4">
                <div class="d-flex align-center justify-space-between grey lighten-2 pa-2 rounded">
                    <h3 class="text-subtitle-1 font-weight-light">Invoice #{{ item.invoice.label }}</h3>
                    <div class="d-flex align-center">
                        <v-chip small>Due: {{ item.invoice.due_date_for_human }}</v-chip>
                        <v-chip small>{{ item.invoice.amount_for_human }}</v-chip>
                    </div>
                </div>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">Account ID</th>
                                <th class="text-left">Description</th>
                                <th class="text-right">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in item.invoice.items"
                                :key="item.account_id"
                            >
                                <td>{{ item.account_id }}</td>
                                <td>{{ item.description }}</td>
                                <td class="text-right">{{ item.amount_with_tax_for_human }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import Http from '@/utils/httpClient';

    export default {
        data() {
            return {
                show: false,
                loading: false,
                customer: null,
                statement: null,
                info: {
                    meta: {}
                }
            }
        },
        methods: {
            open(customer, statement) {
                this.customer = customer;
                this.statement = statement;
                this.show = true;
                this.init();
            },
            init() {
              console.log('account.position.init');
                this.loading = true;
                Http.get(`customers/${this.customer.id}/dashboard/finance/statements/${this.statement.id}`)
                    .then(({ data }) => {
                        this.loading = false;
                        this.info = data;
                    }).catch(() => {
                        this.loading = false;
                    });
            }
        }
    }
</script>
